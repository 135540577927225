import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import CuratorCard from './CuratorCard';

const CuratorCards = ({ data }) => {

  return (
    <Fragment>
      {data?.map((curator) => (
        <div key={curator.name} className='col-lg-3 col-xl-3 col-md-3 col-sm-1 mt-4'>
          <CuratorCard curator={curator} />
        </div>
      ))}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  usd_price: state.market.usd_price,
});
export default connect(mapStateToProps, {})(CuratorCards);
