import React, { Fragment, useState, useEffect } from 'react';
import ProfileHeader from '../components/ProfileHeader';
import Layout from '../components/Layout';
import { Switch, Route } from 'react-router-dom';
import Settings from './sub-routes/Settings';
import Collectibles from './sub-routes/Collectibles';
import Edit from './sub-routes/Edit';
import Info from './sub-routes/Info';
import Wallet from './sub-routes/Wallet';
import Earnings from './sub-routes/Earnings';
import PurchasedCollectibles from './sub-routes/PurchasedCollectibles';
import AirdropCollectibles from './sub-routes/AirdropCollectibles';
import ReceivedCollectibles from './sub-routes/ReceivedCollectibles';
import ProfileNav from '../components/ProfileNav';
import MetaDecorator from '../components/utils/MetaDecorator';

const Profile = ({ match }) => {
  const [activeTab, setActiveTab] = useState('info');
  const { path } = match;
  useEffect(() => {
    setActiveTab(window.location.pathname.split('/')[2]);
  }, [activeTab]);

  return (
    <Fragment>
      <MetaDecorator
        title='Trust Exchange || Collaborate'
        description={`Trust Exchange is an existing software platform that services banks, pharma, green energy and local governments.  Our clients need better data faster.  Our idea is to 'crowd source' the creation and validation of information`}
        imageUrl='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png'
        imageAlt='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png'
      />
      <Layout>
        <ProfileHeader />
        <ProfileNav
          path={path}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Switch>
          <Route exact path={`${path}/settings`} component={Settings} />
          <Route exact path={`${path}/collectibles`} component={Collectibles} />
          <Route
            exact
            path={`${path}/purchases`}
            component={PurchasedCollectibles}
          />
          <Route
            exact
            path={`${path}/airdrop`}
            component={AirdropCollectibles}
          />
          <Route
            exact
            path={`${path}/received`}
            component={ReceivedCollectibles}
          />
          <Route exact path={`${path}/edit`} component={Edit} />
          <Route exact path={`${path}/`} component={Info} />
          <Route exact path={`${path}/wallet`} component={Wallet} />
          <Route exact path={`${path}/earnings`} component={Earnings} />
        </Switch>
      </Layout>
    </Fragment>
  );
};

export default Profile;
