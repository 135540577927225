import {
  LOGIN_SUCCESS,
  AUTH_ERROR,
  SET_USER,
  LOGOUT,
  GET_NOTIFICATIONS,
  READ_NOTIFICATIONS,
  CLEAR_PROFILE,
  GET_FOLLOWING,
  GET_FOLLOWERS,
  GET_ARTISTES_FOLLOWERS,
} from './types';
import { setToastNotification } from '../utils/helpers';
import axios from '../utils/axios';
import setAuthToken from '../utils/setAuthToken';

export const login = (username, refName) => async (dispatch) => {
  try {
    if (!username) {
      return;
    }
    if (!window.hive_keychain) {
      return;
    }
    const ts = Date.now();

    window.hive_keychain.requestSignBuffer(
      username,
      `${username}${ts}`,
      'Posting',
      async (r) => {
        if (r.success) {
          const sig = r.result;
          await dispatch(processLogin(username, ts, sig, refName));
          setToastNotification('Login Successfull', 'success');
        } else {
          setToastNotification('Login Failed, try again', 'error');
        }
      }
    );
  } catch (err) {
    console.error(err.message);
  }
};

export const processLogin = (username, ts, sig, referred_by) => async (dispatch) => {
  // const ref = localStorage.getItem('ref');

  try {
    const data = await axios.get('/auth/login', {
      params: { username, ts, sig, referred_by },
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data.data,
    });
    await dispatch(getNotifications());
    setAuthToken(data.data.token);

    // TODO Login BeeChatter and Fetch Notifications
    // await dispatch('message/beeChatLogin', { username, ts, sig }, { root: true })
    // await dispatch('fetchNotifications')
  } catch (err) {
    console.error(err.message);
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    const username = localStorage.getItem('username');

    let data = await axios.get(
      '/auth/me',
      { params: { username } },
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: SET_USER,
      payload: data.data,
    });
    await dispatch(getNotifications());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    console.error(err.message);
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
  window.location.reload();
};

export const getNotifications = () => async (dispatch) => {
  try {
    const data = await axios.get('/users/notifications');
    const filteredData = data.data.filter((d) => d.read === false);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: filteredData,
    });
  } catch (err) {
    console.error(err.message);
  }
};
export const readNotifications = (id) => async (dispatch) => {
  try {
    let ids = []; 
    if( Array.isArray(id)) {
      ids = [...id];

    } else {
      ids = ids.push(id);
    }
    await axios.post('/users/notifications', { ids: ids });
    dispatch({
      type: READ_NOTIFICATIONS,
      payload: ids,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const whitelistApply = () => async (dispatch) => {
  try {
  } catch (err) {
    console.error(err.message);
  }
};

export const followUser = (username, follow, history) => async (dispatch) => {
  try {
    await axios.post('/users/follow', { username, follow });
    setToastNotification(`Followed @${username} successfully`, 'success')
    history.push(`/@${username}`);
  } catch (err) {
    console.log(err.message);
    setToastNotification('Follow Failed', 'error');
  }
};

export const getFollowing = (username) => async (dispatch) => {
  try {
    const data = await axios.get(`/users/following?username=${username}`);
    dispatch({
      type: GET_FOLLOWING,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getFollowers = (username) => async (dispatch) => {
  try {
    const data = await axios.get(`/users/followers?username=${username}`);
    dispatch({
      type: GET_FOLLOWERS,
      payload: data.data,
    });
    dispatch({
      type: GET_ARTISTES_FOLLOWERS,
      payload: data.data,
    });
  } catch (err) {
    console.error(err.message);
  }
}