import {
  Asset,
  Client,
  PrivateKey,
  PublicKey,
  cryptoUtils,
  utils,
} from '@hiveio/dhive';

// eslint-disable-next-line import/no-anonymous-default-export

const client = new Client(
  ['https://api.hive.blog', 'https://api.openhive.network', 'https://anyx.io'],
  {
    failoverThreshold: 20,
    consoleOnFailover: true,
  },
);

const getClient = () => client;

const hive = {
  Asset,
  Client,
  PrivateKey,
  PublicKey,
  cryptoUtils,
  ...utils,
  getClient,
};

export default hive;
