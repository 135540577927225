export const accordionData = [
  {
    title: 'What is an NFT?',
    content: `An NFT, or Non-Fungible Token, is a unique digital asset that represents ownership of a specific piece of content, such as digital artwork, music, videos, virtual real estate, or collectibles. NFTs are built on blockchain technology, which provides a decentralized and transparent way to verify and track ownership and provenance. Unlike cryptocurrencies like Bitcoin, each NFT is distinct and non-interchangeable, giving it a unique value based on its rarity, demand, and the content it represents.`,
  },
  {
    title: 'How are they being used?',
    content: `NFTs are used in digital art, collectibles, music, virtual real estate, domain names, fashion, and intellectual property. They enable creators to maintain ownership and monetize creations while allowing buyers to collect, display, or resell digital assets. NFTs can be used to buy and sell licenses and related merchandis within the trust exchange ecosystem.`,
  },
  {
    title: 'Will they really be around forever?',
    content: `The future of NFTs depends on scalable platforms, legal frameworks, and addressing environmental concerns. Their current impact and growth potential suggest they'll likely play a significant role in the digital landscape. However, content longevity relies on robust storage solutions and preservation techniques.`,
  },
  {
    title: 'Why would you collect NFTs?',
    content: `
    <ul>
      <li>Digital Ownership: NFTs provide a way for individuals to claim ownership of unique digital assets, such as artwork, music, or collectibles. This ownership can be verified and tracked on the blockchain, ensuring the provenance and authenticity of the asset</li>
      <li>Financial Investment: Some individuals view NFTs as a form of investment, hoping that the value of the digital asset will appreciate over time. This can be driven by factors such as the artist's or creator's reputation, the rarity of the NFT, or the demand for similar digital assets.</li>
      <li>Some NFTs offer utility or functionality beyond simple ownership, such as access to exclusive events, virtual experiences, or membership in online communities.</li>
    </ul>
    `,
  },
  {
    title: 'How do I get started?',
    content: `To begin creating and collecting you will need a Hive account and Hive Keychain. Hive Keychain is a browser extension that securely encrypts your keys and allows you to safely and easily authorize transactions on the platform.

    Get a free account on the GET STARTED tab
    Install the Hive Keychain browser extension: <a href='https://hive-keychain.com/' rel='noopener' target='_blank'>https://hive-keychain.com/</a>`,
  },
  {
    title: 'What is Hive-Engine and why do we need it?',
    content: `Hive-Engine is a smart contract platform built on the Hive Blockchain. One of the smart contracts on Hive-Engine is the NFT contract that Trust Exchange uses to operate the digital marketplace.`,
  },
  {
    title: 'What is SWAP.HIVE?',
    content: `The Hive blockchain uses the HIVE cryptocurrency as the medium of exchange. Hive-Engine utilizes a HIVE backed token called “SWAP.HIVE” to execute all the smart contracts. To interact with Trust Exchange users are required to convert Hive to SWAP.HIVE. You can do this in your wallet on nftshowroom.com by clicking the deposit button and entering the desired amount. Simply sign the transaction with keychain and your Hive will be deposited as SWAP.HIVE.

        There is a 1% fee when converting between HIVE and SWAP.HIVE.
        `,
  },
  {
    title: 'Where are my creations stored?',
    content: `All images uploaded to Trust Exchange are stored on IPFS (InterPlanetary File System) and also backed up on an image server.`,
  },
  {
    title: 'What are HIVE and Hive Power?',
    content: `HIVE is a cryptocurrency that powers the Hive blockchain. On Hive you can own liquid HIVE or choose to stake (freeze it) as HIVE Power (HP). HP grants you voting rights on the platform as well as resource credits. Resource credits are consumed when you transact on the blockchain (e.g. tokenizing art NFTs or blogging on DAPPs like peakd.com) These credits regenerate. We recommend you have at least 5 HP staked to be able to smoothly transact. For more information about the Hive blockchain such as wallets, dapps, exchanges and more please visit <a href='https://hive.io/' rel='noopener' target='_blank'>https://hive.io/</a>`,
  },
  {
    title: 'What is Keychain?',
    content: `Hive Keychain is a browser extension that works with Chrome, Brave, and Mozilla to encrypt your keys within a browser. By using Keychain you can prevent your keys from ever being shared with a malicious website.`,
  },
  {
    title: 'Why is the sale price in SWAP.HIVE, HBD and HIVE?',
    content: `The smart contract platfrom Hive-Engine, which is the backend of the NFT Showroom website, requires that all contracts be settled in SWAP.HIVE, HBD or HIVE.`,
  },
  {
    title: 'Can I get a refund?',
    content: `Blockchain transactions are irreversible, so all sales are final.`,
  },
  {
    title: 'Contact and support',
    content: `For all support needs please contact us in discord.`,
  },
];

export const hiveQuestions = [];
