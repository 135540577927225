import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// import VideoPlayer from "react-video-markers";
import {
  getArtisteCollectibles,
} from '../../actions/collectibles';
import Cards from '../../components/cards/Cards';
import UploadLoader from '../../components/UploadLoader';

const ArtisteCollectibles = ({
  collectibles: { artiste_collectibles, loading },
  getArtisteCollectibles, username,
}) => {




  
  useEffect(() => {
    getArtisteCollectibles(username);
    
    // history.replace(`@${username}`);
  }, [getArtisteCollectibles, username]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sub__profile__header mt-4'>
              <h6>Here the events curated by @{username}</h6>
            </div>
          </div>
        </div>
        <div className='slick__row' style={{ position: 'relative' }}>
          <div className='arrow__wrapper left__'>
            <i className='fa fa-chevron-left'></i>
          </div>
          <div className='row'>
            {artiste_collectibles === null ? (
              <UploadLoader size='small' />
            ) : artiste_collectibles.length > 0 ? (
              <Cards
                songs={artiste_collectibles}
                market={true}
                home={true}
              />
            ) : (
              <div className='col-md-6 offset-3'>
                <div className='card mt-4 nfttunz__card'>
                  <div className='card-body'>
                    <h2 className='text-center'>Nothing here yet...</h2>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='arrow__wrapper right__'>
            <i className='fa fa-chevron-right'></i>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles,
});
export default connect(mapStateToProps, {
  getArtisteCollectibles,
})(ArtisteCollectibles);
