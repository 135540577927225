import React, { Fragment, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { connect } from 'react-redux';
import { removeFromCart } from '../actions/cart';
import { getLicense } from '../actions/licenses';
import { buyToken } from '../actions/token';
import { getBalances } from '../actions/nfts';
import { startPurchase, validatePaypalPurchase } from '../actions/payments';
import { toFixedWithoutRounding } from '../utils/helpers';
import PaypalCheckout from '../components/PaypalCheckout';
import CoinPaymentsDetails from '../components/modals/CoinPaymentsDetails';
import CheckoutWithHive from '../components/modals/CheckoutWithHive';
import hive from '../utils/hive';

const Checkout = ({
  cart,
  total,
  usd_price,
  buyToken,
  getBalances,
  balances,
  username,
  currency,
  startPurchase,
  validatePaypalPurchase,
  getLicense,
  license,
  license_loading,
  license_prices,
  payments,
  match,
}) => {
  const {
    params: { series },
  } = match;
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowPaymentInfo, setIsShowPaymentInfo] = useState(false);
  const [quantity, setQuantity] = useState(1000);
  const [hiveBalance, setHiveBalance] = useState('0.000 HIVE');

  useEffect(() => {
    hive.getClient().database.getAccounts(['bait002']).then(res => {
      setHiveBalance(res[0]?.balance);
    }).catch(err => {
      console.log(err);
    });
    getBalances(username, currency);
    getLicense(series);
  }, [getBalances, getLicense, username, currency, series]);
  return (
    <Fragment>
      <Layout>
        <div className='section__wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-9 offset-2'>
                <div className='cart__header__wrapper'>
                  <h2 className='cart__header__title'>Buy License</h2>
                  <p>Kindly review your choices before checking out.</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-2'></div>
              <div className='col-md-6'>
                <div className='cart__item__card__left'>
                  {!successful ? (
                    license ? (
                      <>
                        <div className='cart__img__wrapper'>
                          <img
                            src={license?.file}
                            alt={series?.name}
                            width={150}
                          />
                        </div>
                        <div className='cart__item__text__wrapper'>
                          <h2 className='cart__header__title fw-light'>
                            {license?.name}
                          </h2>
                          <p>{license?.description}</p>
                        </div>
                      </>
                    ) : (
                      <div className='empty__cart__info__wrapper'>
                        <h4>There nothing to see here...</h4>
                        <p>
                          Make sure you have an item selected to make your
                          purchase.
                        </p>
                      </div>
                    )
                  ) : (
                    <div className='empty__cart__info__wrapper'>
                      <h4>Congratulations!</h4>
                      <p>
                        You have acquired a Trust Exchange License. You can now
                        goto your dashboard to start collaborating with others
                        in our network.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-md-3'>
                <div className='cart__item__card'>
                  <h3 className='cart__preview__title'>Price Details</h3>
                  <p>
                    Cost of license
                    <br />
                    <strong className='text-warning'>
                      {toFixedWithoutRounding(
                        license_prices?.[`${license?.foil}`] /
                          Number(usd_price),
                      )}{' '}
                      HIVE
                    </strong>{' '}
                    <strong>OR</strong>{' '}
                    <strong>
                      $
                      {toFixedWithoutRounding(
                        Number(license_prices?.[`${license?.foil}`]),
                      )}
                    </strong>
                  </p>
                  <br />
                  <p>
                    <strong className='text-warning'>Balances:</strong> <br />
                    <span>
                      {toFixedWithoutRounding(balances[0]?.balance)} SWAP.HIVE
                    </span>
                    <br />
                    <span>{hiveBalance}</span>
                  </p>
                  {balances[0]?.balance < total && (
                    <>
                      <strong
                        className='text-muted'
                        style={{ fontSize: '10px', color: 'red' }}
                      >
                        Oops! You do not have enough {currency} to make this
                        purchase
                      </strong>
                      <br />
                    </>
                  )}
                  <button
                    className='mt-2 mb-2 d-block checkout__button'
                    // disabled={
                    //   balances[0]?.balance < total || total === 0 ? true : false
                    // }
                    onClick={
                      () => setIsShow(true)
                      // buyToken(
                      //   total,
                      //   cart.map((c) => c.nft_id.toString()),
                      // )
                    }
                  >
                    Checkout
                  </button>
                  <div className='row px-3'>
                    <div className='col-md-12'>
                      <h6 className='bold my-2'>OR</h6>
                    </div>
                  </div>
                  <input
                    type='number'
                    name='price'
                    id='price'
                    defaultValue={license_prices?.[`${license?.foil}`]}
                    style={{ display: 'none' }}
                  />
                  {!license_loading && (
                    <PaypalCheckout
                      startPurchase={startPurchase}
                      validatePaypalPurchase={validatePaypalPurchase}
                      series={series}
                      username={username}
                      setLoading={setLoading}
                      isLoading={loading}
                      price={license_prices?.[`${license?.foil}`]}
                      hive_price={license?.hive_price}
                      foil={license?.foil}
                      type={license?.type}
                      setSuccessful={setSuccessful}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <CheckoutWithHive
        isShow={isShow}
        setIsShow={setIsShow}
        setQuantity={setQuantity}
        quantity={quantity}
        setLoading={setLoading}
        isShowPaymentInfo={isShowPaymentInfo}
        setIsShowPaymentInfo={setIsShowPaymentInfo}
        foil={license?.foil}
        type={license?.type}
        series={series}
        price={license_prices?.[`${license?.foil}`]}
      />
      <CoinPaymentsDetails
        payments={payments}
        isShow={isShowPaymentInfo}
        setIsShow={setIsShowPaymentInfo}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  cart: state.cart.cart,
  total: state.cart.total,
  usd_price: state.market.usd_price,
  balances: state.nfts.balances,
  username: state.users.username,
  currency: state.settings.currency,
  license_prices: state.settings.license_prices,
  license: state.licenses.license,
  license_loading: state.licenses.loading,
  payments: state.payments,
});
export default connect(mapStateToProps, {
  removeFromCart,
  buyToken,
  getBalances,
  startPurchase,
  validatePaypalPurchase,
  getLicense,
})(Checkout);
