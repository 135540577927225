import React, { Fragment } from 'react';
import { setToastNotification } from '../utils/helpers';

const ArtisteNav = ({ path, setActiveTab, activeTab, username, history }) => {
  return (
    <Fragment>
      <div className='nfttunz__profile__nav'>
        <ul className='nfttunz__profile__links'>
          <li className='nfttunz__profile__link'>
            <button className={activeTab === 'all' ? 'active__link' : ''} onClick={() => {
              history.push(`@${username}/listed`);
              history.location.pathname = `/@${username}`;
              setActiveTab('all')}}>
              All Events
            </button>
          </li>
          <li className='nfttunz__profile__link'>
            <button className={activeTab === 'on-sale' ? 'active__link' : ''} onClick={() => {
              history.push(`@${username}/store`);
              history.location.pathname = `/@${username}`;
              setActiveTab('on-sale')}}>Validated Events</button>
          </li>
          <li className='nfttunz__profile__link'>
            <button className={activeTab === 'communities' ? 'active__link' : ''} onClick={() => {
              setToastNotification('Coming soon!', 'info');
              return
              }}>Curated Events</button>
          </li>
          <li className='nfttunz__profile__link'>
            <button className={activeTab === 'fans' ? 'active__link' : ''} onClick={() => {
              setToastNotification('Coming soon!', 'info');
              return;
              }}>Licenses</button>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default ArtisteNav;
