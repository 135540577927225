/* eslint-disable no-mixed-operators */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import {
  getCollectible,
  getCollectibles,
  fetchInterests,
  fetchOnMarket,
  getSeriesString,
} from '../actions/collectibles';
import {
  sellToken,
  buyToken,
  changePrice,
  cancelSell,
  auctionToken,
  cancelAuction,
  settleAuction,
} from '../actions/token';
import {
  getNFTInstances,
  getNFTInstance,
  getNFTDefinition,
  getNFTSellBook,
  transferNFT,
  airdropTokens,
  airdropNFT,
  whaleDrop,
  getBalances,
  getAuctionInstances,
} from '../actions/nfts';
import { getCommunity } from '../actions/communities';
import {
  addSellCart,
  removeSellCart,
  clearSellCart,
  addPriceCart,
  removePriceCart,
  clearPriceCart,
} from '../actions/cart';
import TransferModal from '../components/modals/TransferModal';
import Instances from '../components/market/Instances';
import Loader from '../components/Loader';

const Collectible = ({
  match,
  getCollectible,
  getCollectibles,
  getNFTDefinition,
  getNFTInstances,
  getNFTInstance,
  getNFTSellBook,
  fetchInterests,
  fetchOnMarket,
  getSeriesString,
  buyToken,
  auctionToken,
  addSellCart,
  removeSellCart,
  clearSellCart,
  clearPriceCart,
  collectibles: { collectible, loading: globalCollectibleLoading },
  username,
  sell_cart,
  nfts: { instances, loading, instanceLoading },
  transferNFT,
  usd_price,
  getBalances,
  getAuctionInstances,
  getCommunity,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isShowPrice, setIsShowPrice] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isShowAuction, setIsShowAuction] = useState(false);
  const [isShowTransfer, setIsShowTransfer] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [collectibleLoading, setCollectibleLoading] = useState(false);
  const [receipient, setReceipient] = useState('');
  const [memo, setMemo] = useState('');
  const [instanceToSell, setInstanceToSell] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [airdropLoading, setAirdropLoading] = useState(false);

  const handleSelectAll = (e, instances) => {
    if (!e.target.checked) {
      clearSellCart();
      return;
    }
    let sellArr = [];
    instances.forEach((ins) => {
      if (sell_cart.includes(ins.toString())) {
        sellArr.filter((i) => i !== ins);
      } else {
        sellArr.push(ins._id.toString());
      }
    });
    addSellCart([...sellArr]);
    return;
  };

  const {
    params: { series },
  } = match;
  const authorEnd = series.indexOf('_');
  const author = series.substring(0, authorEnd);
  useEffect(() => {
    getCollectible(series);
    getCollectibles();
    getNFTDefinition();
    getNFTInstances(
      {
        account: username,
        'properties.series': series,
      },
      series,
    );
    getBalances(username, 'BEE');
    // TODO: Check here again to make sure nothing breaks due to these commented out lines - seems like this reduced the number of requests made to the RPC node
    // getNFTSellBook({
    //   account: username,
    //   'grouping.series': series,
    // });
    // fetchInterests();
    // fetchOnMarket({
    //   account: username,
    // });
    // getAuctionInstances(series, username);
    getSeriesString({ account: username });
    getCommunity(series);
    return () => {
      clearSellCart();
      clearPriceCart();
    };
  }, [
    series,
    username,
    getCollectible,
    getCollectibles,
    getNFTDefinition,
    getNFTInstances,
    getNFTInstance,
    getNFTSellBook,
    fetchInterests,
    fetchOnMarket,
    getSeriesString,
    author,
    clearSellCart,
    clearPriceCart,
    getBalances,
    getCommunity,
    getAuctionInstances,
  ]);

  if (loading || globalCollectibleLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Layout>
        {globalCollectibleLoading ? (
          <Loader />
        ) : (
          <>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='collectible__title mt-3'>
                    <h2 className='text-center'>{collectible?.name}</h2>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                  <div
                    className={`file__player mt-4 ${
                      (collectible?.type === 'audio' && 'w-100') ||
                      (collectible?.type === 'fan' && 'w-100')
                    }`}
                  >
                    <div style={{ width: '100%' }}>
                      <div className='audio__thumbnail'>
                        <div
                          className='audio__thumbnail__blurred__background'
                          style={{
                            backgroundImage: `url(${collectible?.file})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        ></div>
                        <div className='audio__thumbnail__blurred__overlay'></div>
                        <img
                          src={collectible?.file}
                          alt='thumbnail'
                          width='100%'
                          height='450px'
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                    </div>
                  </div>
                  <Fragment>
                    <div className='collectible__description mt-3'>
                      <p className='text-center'>
                        <strong>Description:</strong> {collectible?.description}
                      </p>
                      <p className='text-center'>
                        <strong>Type:</strong> {collectible?.type}
                      </p>
                      <p className='text-center'>
                        <strong>Max Editions:</strong> {collectible?.editions}
                      </p>

                      <br />
                    </div>
                  </Fragment>

                  <Fragment>
                    <div className='d-flex justify-content-lg-between'>
                      <h4>Available for Sale</h4>
                    </div>
                    <hr />
                    {instances?.length > 0 ? (
                      <Instances
                        handleSelectAll={handleSelectAll}
                        instances={instances}
                        setInstanceToSell={setInstanceToSell}
                        setIsShowPrice={setIsShowPrice}
                        setIsShowTransfer={setIsShowTransfer}
                        setIsShowAuction={setIsShowAuction}
                        buyToken={buyToken}
                        auctionToken={auctionToken}
                        usd_price={usd_price}
                        sell_cart={sell_cart}
                        removeSellCart={removeSellCart}
                        addSellCart={addSellCart}
                        username={username}
                        transferNFT={transferNFT}
                        setLoading={setCollectibleLoading}
                        loading={instanceLoading}
                        getInstances={getNFTInstances}
                        series={series}
                      />
                    ) : (
                      <Fragment>
                        <h6 className='text-center mt-3'>
                          No NFTs avaialble here for listing...
                        </h6>
                      </Fragment>
                    )}
                  </Fragment>
                </div>
              </div>
              <hr />
            </div>
            <TransferModal
              isShow={isShowTransfer}
              setIsShow={setIsShowTransfer}
              setReceipient={setReceipient}
              receipient={receipient}
              setMemo={setMemo}
              memo={memo}
              instance={instanceToSell}
              transferNFT={transferNFT}
              username={username}
              setLoading={setCollectibleLoading}
            />
          </>
        )}
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles,
  communities: state.communities,
  nfts: state.nfts,
  username: state.users.username,
  sell_cart: state.cart.sell_cart,
  price_cart: state.cart.price_cart,
  series_strings: state.market.series,
  usd_price: state.market.usd_price,
  currency: state.settings.currency,
});
export default connect(mapStateToProps, {
  getCollectible,
  getCollectibles,
  sellToken,
  buyToken,
  changePrice,
  cancelSell,
  auctionToken,
  cancelAuction,
  settleAuction,
  getNFTDefinition,
  getNFTInstances,
  getNFTInstance,
  getNFTSellBook,
  fetchInterests,
  fetchOnMarket,
  getSeriesString,
  addSellCart,
  removeSellCart,
  clearSellCart,
  removePriceCart,
  addPriceCart,
  clearPriceCart,
  transferNFT,
  airdropTokens,
  whaleDrop,
  airdropNFT,
  getBalances,
  getCommunity,
  getAuctionInstances,
})(Collectible);
