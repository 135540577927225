import {
  MINT_TOKEN,
  CLEAR_CART,
  WITHDRAW_HIVE,
  COLLECTIBLES_LOADING,
  AUCTION_LOADING,
} from './types';
import store from '../store';
import {
  setToastNotification,
  toFixedWithoutRounding,
} from '../utils/helpers';
import { validateTransaction } from './transactions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const createToken = (tokenPayload, history) => async (dispatch) => {
  const settings = await store.getState().settings;
  const username = await store.getState().users.username;
  const fee = toFixedWithoutRounding(
    settings.token_issuance_base_fee +
      settings.token_issuance_fee * tokenPayload.editions,
    3,
  );

  const json = {
    contractName: 'tokens',
    contractAction: 'transfer',
    contractPayload: {
      symbol: settings.currency,
      to: settings.account,
      quantity: fee.toString(),
      memo: JSON.stringify(tokenPayload),
    },
  };
  const jsonData = {
    id: settings.sidechain_id,
    key: 'Active',
    data: json,
    message: 'Issue Token',
    eventName: 'issue-tokens-successful',
  };

  window.hive_keychain.requestCustomJson(
    username,
    jsonData.id,
    jsonData.key,
    JSON.stringify(jsonData.data),
    jsonData.message,
    (r) => {
      if (r.success) {
        console.log(r);
        setToastNotification(r.message, 'success');
        setTimeout(() => {
          history.push('/');
        }, 11000);
      }
    },
  );
  dispatch({
    type: MINT_TOKEN,
    payload: 'token stuffs',
  });
};

export const sellToken = (price, nft) => async (dispatch) => {
  try {
    let nfts = [];
    if (typeof nft === 'string') {
      nfts.push(nft.toString());
    } else {
      nfts = nfts.concat(nft);
    }
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;

    const json = {
      contractName: 'nftmarket',
      contractAction: 'sell',
      contractPayload: {
        priceSymbol: settings.currency,
        symbol: settings.nft_symbol,
        nfts,
        price: price.toString(),
        fee: settings.market_fee,
      },
    };
    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Sell NFT',
      eventName: 'nft-sell-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};
export const buyToken = (price, nft, history) => async (dispatch) => {
  try {
    let nfts = [];
    if (typeof nft === 'string') {
      nfts.push(nft);
    } else {
      nfts = nfts.concat(nft);
    }

    const settings = await store.getState().settings;
    const username = await store.getState().users.username;
    const json = {
      contractName: 'nftmarket',
      contractAction: 'buy',
      contractPayload: {
        symbol: settings.nft_symbol,
        nfts,
        marketAccount: 'nfttunz',
        expPrice: price.toString(),
        expPriceSymbol: settings.currency,
      },
    };

    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Buy NFT',
      eventName: 'nft-buy-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            dispatch({
              type: CLEAR_CART,
            });
            window.location.reload();
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};

export const changePrice = (tokenPayload, price, nft) => async (dispatch) => {
  try {
    let nfts = [];
    if (typeof nft === 'string') {
      nfts.push(nft);
    } else {
      nfts = nfts.concat(nft);
    }
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;
    const json = {
      contractName: 'nftmarket',
      contractAction: 'changePrice',
      contractPayload: {
        symbol: settings.nft_symbol,
        nfts,
        price: price.toString(),
      },
    };
    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Change Price NFT',
      eventName: 'nft-change-price-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};

export const cancelSell = (nft) => async (dispatch) => {
  try {
    const nfts = [];
    nfts.push(nft.toString());
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;
    const json = {
      contractName: 'nftmarket',
      contractAction: 'cancel',
      contractPayload: {
        symbol: settings.nft_symbol,
        nfts,
      },
    };
    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Change Price NFT',
      eventName: 'nft-change-price-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};

export const withdrawHive = (query, history) => async (dispatch) => {
  try {
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;

    const json = {
      contractName: 'hivepegged',
      contractAction: 'withdraw',
      contractPayload: {
        quantity: String(query),
      },
    };
    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Withdraw Token',
      eventName: 'withdraw-tokens-successful',
    };

    await window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r.result.id);
          await validateTransaction(r.result.id);
          dispatch({
            type: WITHDRAW_HIVE,
            payload: false,
          });
          window.location.reload();
        }
      },
    );
  } catch (err) {
    console.log(err.message);
  }
};

export const auctionToken =
  (minBid, finalPrice, expiry, nft, history) => async (dispatch) => {
    dispatch({
      type: COLLECTIBLES_LOADING,
      payload: true,
    });
    try {
      let nfts = [];
      if (typeof nft === 'string') {
        nfts.push(nft);
      } else {
        nfts = nfts.concat(nft);
      }
      const expiryDate = dayjs()
        .add(expiry, 'minutes')
        .utc()
        .format()
        .replace(/Z$/g, '');
      const settings = await store.getState().settings;
      const username = await store.getState().users.username;
      const json = {
        contractName: 'nftauction',
        contractAction: 'create',
        contractPayload: {
          symbol: settings.nft_symbol,
          nfts,
          minBid,
          finalPrice,
          priceSymbol: settings.currency,
          feePercent: 2000,
          expiry: expiryDate,
        },
      };

      const jsonData = {
        id: settings.sidechain_id,
        key: 'Active',
        data: json,
        message: 'Auction NFT',
        eventName: 'nft-auction-create-successful',
      };
      window.hive_keychain.requestCustomJson(
        username,
        jsonData.id,
        jsonData.key,
        JSON.stringify(jsonData.data),
        jsonData.message,
        async (r) => {
          if (r.success) {
            console.log(r);
            await validateTransaction(r.result.id);
            setTimeout(() => {
              dispatch({
                type: CLEAR_CART,
              });
              dispatch({
                type: COLLECTIBLES_LOADING,
                payload: false,
              });
            }, 3000);
          }
        },
      );
    } catch (err) {
      console.error(err.message);
    }
  };
export const cancelAuction = (auctionId) => async (dispatch) => {
  dispatch({
    type: COLLECTIBLES_LOADING,
    payload: true,
  });
  try {
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;
    const json = {
      contractName: 'nftauction',
      contractAction: 'cancel',
      contractPayload: {
        auctionId,
      },
    };

    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Cancel Auction',
      eventName: 'nft-auction-cancel-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            dispatch({
              type: CLEAR_CART,
            });
            dispatch({
              type: COLLECTIBLES_LOADING,
              payload: false,
            });
            // window.location.reload();
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};
export const settleAuction = (auctionId, bidder) => async (dispatch) => {
  try {
    dispatch({
      type: COLLECTIBLES_LOADING,
      payload: true,
    });
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;
    const json = {
      contractName: 'nftauction',
      contractAction: 'settle',
      contractPayload: {
        auctionId,
        account: bidder
      },
    };

    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Cancel Auction',
      eventName: 'nft-auction-cancel-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            dispatch({
              type: CLEAR_CART,
            });
            dispatch({
              type: COLLECTIBLES_LOADING,
              payload: false,
            });
            // window.location.reload();
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};
export const placeBid = (auctionId, bid) => async (dispatch) => {
  try {
    dispatch({
      type: AUCTION_LOADING,
      payload: true,
    });
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;
    const json = {
      contractName: 'nftauction',
      contractAction: 'bid',
      contractPayload: {
        auctionId,
        bid: bid.toString(),
        marketAccount: settings.account,
      },
    };

    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Place Bid',
      eventName: 'nft-place-bid-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            dispatch({
              type: CLEAR_CART,
            });
            dispatch({
              type: AUCTION_LOADING,
              payload: false,
            });
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};
export const cancelBid = (auctionId) => async (dispatch) => {
  dispatch({
    type: COLLECTIBLES_LOADING,
    payload: true,
  });
  try {
    const settings = await store.getState().settings;
    const username = await store.getState().users.username;
    const json = {
      contractName: 'nftauction',
      contractAction: 'cancelBid',
      contractPayload: {
        auctionId,
      },
    };

    const jsonData = {
      id: settings.sidechain_id,
      key: 'Active',
      data: json,
      message: 'Cancel Bid',
      eventName: 'nft-bid-cancel-successful',
    };
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      async (r) => {
        if (r.success) {
          console.log(r);
          await validateTransaction(r.result.id);
          setTimeout(() => {
            dispatch({
              type: CLEAR_CART,
            });
            dispatch({
              type: COLLECTIBLES_LOADING,
              payload: false,
            });
            // window.location.reload();
          }, 3000);
        }
      },
    );
  } catch (err) {
    console.error(err.message);
  }
};