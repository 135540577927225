import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { getMarketListed } from '../actions/market';
import { getCollectibles, searchCollectibles } from '../actions/collectibles';
import { getMarket, getMarketByFilters } from '../actions/market';
import Cards from '../components/cards/Cards';
// import Header from '../components/Header';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import Tags from '../components/Tags';
import { tags } from '../utils/constants';
import UploadLoader from '../components/UploadLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
// import SearchField from '../components/SearchField';
import MetaDecorator from '../components/utils/MetaDecorator';

const Market = ({
  getCollectibles,
  searchCollectibles,
  getMarket,
  getMarketByFilters,
  collectibles,
  settings: { filters, categories },
  market: {
    market,
    loading,
    pagination: { market_length },
  },
}) => {
  // const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sort_by, setSortBy] = useState('newest');
  const [price_min, setPriceMin] = useState('');
  const [price_max, setPriceMax] = useState('');
  const [rights, setRights] = useState('');
  const [category, setCategory] = useState('');
  const [showPlayer, setShowPlayer] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   searchCollectibles(search);
  // };
  const handleSearch = async (q) => {
    if (!q) {
      await getMarket();
      await handleFilter();
    }
    await searchCollectibles(q);
    await handleFilter();
    return;
  };
  const handleFilter = async () => {
    await getMarketByFilters(
      page,
      sort_by,
      price_min,
      price_max,
      rights,
      category,
    );
  };
  const fetchMoreData = (page) => {
    const newPage = page + 1;
    setTimeout(() => {
      setPage(newPage);
    }, 1500);
  };
  useEffect(() => {
    // getCollectibles(page);
    getMarket(page);
    return () => {};
  }, [getCollectibles, getMarket, page]);
  return (
    <Fragment>
      <MetaDecorator
        title='Trust Exchange || Market Place'
        description={`Trust Exchange is an existing software platform that services banks, pharma, green energy and local governments.  Our clients need better data faster.  Our idea is to 'crowd source' the creation and validation of information`}
        imageUrl='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png'
        imageAlt='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png'
      />
      <Layout>
        {/* <Header title='Gallery' button={false} /> */}
        <section className='section__wrapper' style={{ minHeight: '450px' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section__title text-center'>
                  <h2>Market Place</h2>
                </div>
              </div>
            </div>
            <div className='row d-none'>
              <div className='col-md-12'>
                <br />
                <div className='tags__main__wrapper'>
                  <Tags tags={tags} handleSearch={handleSearch} />
                </div>
              </div>
            </div>
            {/* <SearchField
              handleSearch={handleSearch}
              handleSubmit={handleSubmit}
              setSearch={setSearch}
            /> */}
            <div className='row mb-2 mt-4'>
              <div className='col-md-1'></div>
              <div className='col-md-4'>
                <div className='double__input__row'>
                  <div className='nfttunz__input__wrapper nfttunz__filter__input__border w-50 d-flex'>
                    <select
                      className='nfttunz__filter__select w-100'
                      name='rights'
                      id='rights'
                      defaultValue='0'
                      onChange={(e) => {
                        setRights(e.target.value);
                      }}
                    >
                      <option value='0' disabled>
                        Select rights
                      </option>
                      <option value=''>Any</option>
                      <option value='1'>Private</option>

                      <option value='2'>Public</option>
                    </select>
                  </div>
                  <div className='nfttunz__input__wrapper nfttunz__filter__input__border w-50 d-flex'>
                    <select
                      placeholder='Enter rights'
                      className='nfttunz__filter__select w-100'
                      name='rights'
                      defaultValue='1'
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    >
                      <option value='1' disabled>
                        Select Category
                      </option>
                      <option value=''>All</option>
                      {categories.map((fil, i) => (
                        <option key={i} value={fil}>
                          {fil}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='double__input__row'>
                  <div className='nfttunz__input__wrapper nfttunz__filter__input__border w-50 d-flex'>
                    <input
                      value={price_min}
                      onChange={(e) => setPriceMin(e.target.value)}
                      className='nfttunz__filter__input'
                      type='number'
                      placeholder='Min Price'
                      aria-label='editions'
                    />
                  </div>
                  <div className='nfttunz__input__wrapper nfttunz__filter__input__border w-50 d-flex'>
                    <input
                      value={price_max}
                      onChange={(e) => setPriceMax(e.target.value)}
                      className='nfttunz__filter__input'
                      type='number'
                      placeholder='Max Price'
                      aria-label='editions'
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='double__input__row'>
                  <div className='nfttunz__input__wrapper nfttunz__filter__input__border w-50 d-flex'>
                    <select
                      className='nfttunz__filter__select w-100'
                      name='rights'
                      id='rights'
                      onChange={(e) => {
                        setSortBy(e.target.value);
                      }}
                      defaultValue='0'
                    >
                      <option value='0' disabled>
                        Sort By
                      </option>
                      <option value='newest'>Newest</option>

                      <option value='oldest'>Oldest</option>
                      <option value='updated'>Updated</option>
                      <option value='price_asc'>Price Ascending</option>
                      <option value='price_desc'>Price Descending</option>
                    </select>
                  </div>
                  <div className='nfttunz__input__wrapper nfttunz__filter__input__border w-50 d-flex'>
                    <input
                      value='Filter'
                      onClick={handleFilter}
                      className='nfttunz__filter__input filter__button'
                      type='button'
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-1'></div>
            </div>
            <div className='row'>
              {market.length < 1 ? (
                loading ? (
                  <Loader />
                ) : (
                  <h4 className='text-center p-5'>
                    No data available here yet!
                  </h4>
                )
              ) : (
                <InfiniteScroll
                  className='row'
                  dataLength={market.length}
                  next={() => fetchMoreData(page)}
                  hasMore={market.length < market_length ? true : false}
                  loader={
                    <h4 className='text-center mt-4'>
                      <UploadLoader size='small' />
                    </h4>
                  }
                  endMessage={
                    <p style={{ textAlign: 'center', marginTop: '30px' }}>
                      <b>Yess! You have seen it all 😁</b>
                    </p>
                  }
                >
                  <Cards
                    songs={market}
                    market={true}
                    showPlayer={showPlayer}
                    setShowPlayer={setShowPlayer}
                    loading={loading}
                  />
                </InfiniteScroll>
              )}
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles.collectibles,
  market: state.market,
  settings: state.settings,
});
export default connect(mapStateToProps, {
  getCollectibles,
  getMarket,
  getMarketByFilters,
  searchCollectibles,
})(Market);
