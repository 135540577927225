import React, { Fragment, useEffect, useRef } from 'react';
import { setToastNotification } from '../utils/helpers';

const PaypalCheckout = ({
  setLoading,
  username,
  startPurchase,
  validatePaypalPurchase,
  series,
  hive_price,
  price,
  setSuccessful,
  foil,
  type,
}) => {
  const paypalRef = useRef();
  const amount = window.document.getElementById('price')?.value;
  useEffect(() => {
    if (!window.mybutton)
      window.mybutton = window.paypal
        .Buttons({
          style: {
            layout: 'horizontal',
            height: 50,
            width: 150,
            shape: 'rect',
            size: 'responsive',
            tagline: false,
            display: 'paypal',
          },
          createOrder: async (data, actions) => {
            const usd_price = window.document.getElementById('price')?.value;
            const payload = {
              username,
              payment_method: 'paypal',
              currency: 'USD',
              items: [
                {
                  type: 'license',
                  series,
                  price: Number(usd_price),
                  foil,
                  license_type: type,
                },
              ],
            };
            const purchaseInfo = await startPurchase(payload);
            // console.log({ purchaseInfo });
            return actions.order.create({
              purchase_units: [
                {
                  reference_id: purchaseInfo.uid,
                  invoice_id: purchaseInfo.uid,
                  amount: {
                    value: usd_price,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            setLoading(false);
            const payload = {
              uid: order.purchase_units[0].reference_id,
              tx: data.orderID,
              foil,
              type,
            };
            // console.log({ payload }, 'validating');
            const purchase = await validatePaypalPurchase(payload);
            // console.log({purchase})
            if (order.status === 'COMPLETED' && purchase.success === 'OK') {
              setToastNotification('Credit purchase successful', 'success');
              setSuccessful(true);
            }
            // window.location.reload();
          },
          onError: (err) => {
            setToastNotification('Something went wrong', 'error');
            console.error(err);
          },
        })
        .render(paypalRef.current);
  }, [
    setLoading,
    startPurchase,
    validatePaypalPurchase,
    username,
    hive_price,
    price,
    series,
    setSuccessful,
    foil,
    type,
    amount,
  ]);
  return (
    <Fragment>
      <div ref={paypalRef} />
    </Fragment>
  );
};

export default PaypalCheckout;
