import React, { Fragment, useState } from 'react';
import ArtisteProfileHeader from '../components/ArtisteProfileHeader';
import Layout from '../components/Layout';
import ArtisteCollectibles from './sub-routes/ArtisteCollectibles';
import MetaDecorator from '../components/utils/MetaDecorator';
import ArtisteNav from '../components/ArtisteNav';
import OnSale from './sub-routes/OnSale';
import { connect } from 'react-redux';

const ArtisteProfile = ({ match, history, artiste_profile }) => {
  const [activeTab, setActiveTab] = useState('all');
  const {
    params: { username },
  } = match;

  return (
    <Fragment>
      <MetaDecorator
        title={`${username.toUpperCase()}'s' Profile`}
        description={`Trust Exchange is an existing software platform that services banks, pharma, green energy and local governments.  Our clients need better data faster.  Our idea is to "crowd source" the creation and validation of information`}
        imageUrl={
          'https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png'
        }
        imageAlt='https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png'
      />
      <Layout>
        <ArtisteProfileHeader param={username} />
        <ArtisteNav
          path={username}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          history={history}
          username={username}
        />
        <div className={activeTab === 'all' ? 'show' : 'hide'}>
          <ArtisteCollectibles
            username={username}
            setActiveTab={setActiveTab}
          />
        </div>

        <div className={activeTab === 'on-sale' ? 'show' : 'hide'}>
          <OnSale username={username} />
        </div>
      </Layout>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  artiste_profile: state.profiles.artiste_profile,
});
export default connect(mapStateToProps, {})(ArtisteProfile);
