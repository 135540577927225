import { SET_SETTINGS } from '../actions/types';

const intialState = {
  prefix: '',
  account: '',
  categories: [],
  filters: [],
  currency: '',
  nft_symbol: 'TRUSTEX',
  nodes: [],
  maintenance: false,
  market_fee: 1000,
  sidechain_id: 'ssc-mainnet-hive',
  sidechain_rpc: 'https://herpc.dtools.dev/',
  token_issuance_base_fee: 0.001,
  token_issuance_fee: 0.001,
  admins: [],
};

const settingsReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SETTINGS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
export default settingsReducer;
