import { GET_LICENSE } from './types';
import axios from '../utils/axios';

export const getLicense = (series) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/collectibles/info?series=${series}`);

    dispatch({
      type: GET_LICENSE,
      payload: data,
    });
  } catch (err) {
    console.error(err.message);
  }
};
