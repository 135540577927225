import {
  SET_PROFILE,
  GET_PROFILE,
  CLEAR_PROFILE,
  GET_ARTISTE_PROFILE,
  GET_ARTISTES,
  GET_ARTISTES_FOLLOWERS,
} from '../actions/types';

const intialState = {
  profile: {
    full_name: 'TREX user',
    avatar: '',
    bio: '',
  },
  artiste_profile: {},
  artiste_followers: [],
  artistes: [],
  loading: true,
};

const profileReducer = (state = intialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: { ...payload },
        loading: false,
      };

    case GET_PROFILE:
      return {
        ...state,
        profile: { ...payload },
        loading: false,
      };
    case GET_ARTISTE_PROFILE:
      return {
        ...state,
        artiste_profile: { ...payload },
        loading: false,
      };
    case GET_ARTISTES_FOLLOWERS:
      return {
        ...state,
        artiste_followers: [...payload],
        loading: false,
      };
    case GET_ARTISTES:
      return {
        ...state,
        artistes: payload,
        loading: false,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default profileReducer;
