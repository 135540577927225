import { START_PURCHASE, VALIDATE_PURCHASE } from './types';
import axios from '../utils/axios';
import { setToastNotification } from '../utils/helpers';

export const startPurchase = (payload) => async (dispatch) => {
  try {
    console.log({ payload });
    const paymentInfo = await axios.post('/purchases/start/credits', payload);
    dispatch({
      type: START_PURCHASE,
      payload: paymentInfo.data,
    });
    return paymentInfo.data;
  } catch (err) {
    console.log(err);
  }
};

export const validatePaypalPurchase = (payload) => async (dispatch) => {
  try {
    const paymentInfo = await axios.post('/purchases/paypal/credits', payload);
    dispatch({
      type: VALIDATE_PURCHASE,
      payload: paymentInfo.data,
    });
    return paymentInfo.data;
  } catch (err) {
    console.log(err);
  }
};
export const validateCryptoPurchase = (payload) => async (dispatch) => {
  try {
    const paymentInfo = await axios.post(
      '/purchases/ipns/coinpayments',
      payload,
    );
    console.log(paymentInfo.data);
    dispatch({
      type: VALIDATE_PURCHASE,
      payload: paymentInfo.data,
    });
    return paymentInfo.data;
  } catch (err) {
    console.log(err);
  }
};

export const convertCredits = (username, jsonData) => async (dispatch) => {
  console.log(jsonData);
  try {
    window.hive_keychain.requestCustomJson(
      username,
      jsonData.id,
      jsonData.key,
      JSON.stringify(jsonData.data),
      jsonData.message,
      (r) => {
        if (r.success) {
          console.log(r);
          setToastNotification(r.message, 'success');
          setTimeout(() => {
            window.location.reload();
            // history.push('/admin');
          }, 20000);
        }
      },
    );
  } catch (err) {
    console.log(err.message);
  }
};
