import { GET_ARTISTE_MARKET, GET_ARTISTE_EARNINGS } from './types';
import axios from '../utils/axios';

export const getArtisteMarket = (artisteUsername) => async (dispatch) => {
  try {
    const data = await axios.get('market');
    const market = data.data.filter(
      (market) => market.creator === artisteUsername,
    );
    dispatch({
      type: GET_ARTISTE_MARKET,
      payload: market,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getArtisteEarning = () => async (dispatch) => {
  try {
    const data = await axios.get('/rewards/user');
    const earnings = data.data;
    dispatch({
      type: GET_ARTISTE_EARNINGS,
      payload: earnings,
    });
  } catch (err) {
    console.error(err.message);
  }
};
