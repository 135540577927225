export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_USER = 'SET_USER';
export const SET_LOADING = 'SET_LOADING';
export const LOGOUT = 'LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const MINT_TOKEN = 'MINT_TOKEN';
export const SET_SETTINGS = 'GET_SETTINGS';
export const GET_COLLECTIBLES = 'GET_COLLECTIBLES';
export const GET_CURATORS_COLLECTIBLES = 'GET_CURATORS_COLLECTIBLES';
export const GET_VALIDATORS_COLLECTIBLES = 'GET_VALIDATORS_COLLECTIBLES';
export const FOLLOW_USER = 'FOLLOW_USER';
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const GET_FOLLOWING = 'GET_FOLLOWING';
export const SET_COLLECTIBLES_LENGTH = 'SET_COLLECTIBLES_LENGTH';
export const GET_USER_COLLECTIBLES = 'GET_USER_COLLECTIBLES';
export const GET_ARTISTE_COLLECTIBLES = 'GET_ARTISTE_COLLECTIBLES';
export const GET_PURCHASED_COLLECTIBLES = 'GET_PURCHASED_COLLECTIBLES';
export const GET_AIRDROP_COLLECTIBLES = 'GET_AIRDROP_COLLECTIBLES';
export const GET_RECEIVED_COLLECTIBLES = 'GET_RECEIVED_COLLECTIBLES';
export const GET_COLLECTIBLE = 'GET_COLLECTIBLE';
export const COLLECTIBLES_LOADING = 'COLLECTIBLES_LOADING';
export const MARKET_LOADING = 'MARKET_LOADING';
export const AUCTION_LOADING = 'AUCTION_LOADING';
export const GET_FEEDS = 'GET_FEEDS';
export const SET_PROFILE = 'SET_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_ARTISTE_PROFILE = 'GET_ARTISTE_PROFILE';
export const GET_ARTISTES = 'GET_ARTISTES';
export const GET_ARTISTES_FOLLOWERS = 'GET_ARTISTES_FOLLOWERS';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_BALANCES = 'GET_BALANCES';
export const GET_NFT_DEFINITION = 'GET_NFT_DEFINITION';
export const GET_NFT_INSTANCES = 'GET_NFT_INSTANCES';
export const GET_NFT_SELLBOOK = 'GET_NFT_SELLBOOK';
export const GET_NFT_AUCTIONS = 'GET_NFT_AUCTIONS';
export const GET_MARKET_LISTED = 'GET_MARKET_LISTED';
export const GET_MARKET = 'GET_MARKET';
export const SET_MORE_INTERESTS = 'SET_MORE_INTERESTS';
export const SET_INTERESTS = 'SET_INTERESTS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_ON_MARKET = 'SET_ON_MARKET';
export const SET_SERIES = 'SET_SERIES';
export const SEARCH_COLLECTIBLES = 'SEARCH_COLLECTIBLES';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const GET_MARKET_TRANSACTIONS = 'GET_MARKET_TRANSACTIONS';
export const GET_ADMIN_TRANSACTION_HISTORY = 'GET_ADMIN_TRANSACTION_HISTORY';
export const GET_EARNINGS_TRANSACTION_HISTORY =
  'GET_EARNINGS_TRANSACTION_HISTORY';
export const WHITELIST_APPLY = 'WHITELIST_APPLY';
export const GET_ADMIN_COLLECTIBLES = 'GET_ADMIN_COLLECTIBLES';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const GET_ADMIN_WHITELIST_APPLICATIONS =
  'GET_ADMIN_WHITELIST_APPLICATIONS';
export const GET_ADMIN_REPORTS = 'GET_ADMIN_REPORTS';
export const ADMIN_APPROVE_WHITELIST = 'ADMIN_APPROVE_WHITELIST';
export const GET_COMMUNITIES = 'GET_COMMUNITIES';
export const GET_COMMUNITY = 'GET_COMMUNITY';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_SELL_CART = 'ADD_SELL_CART';
export const REMOVE_SELL_CART = 'REMOVE_SELL_CART';
export const ADD_PRICE_CART = 'ADD_PRICE_CART';
export const REMOVE_PRICE_CART = 'REMOVE_PRICE_CART';
export const CLEAR_PRICE_CART = 'CLEAR_PRICE_CART';
export const CLEAR_SELL_CART = 'CLEAR_SELL_CART';
export const SET_USD_PRICE = 'SET_USD_PRICE';
export const SET_TRANSACTION_LENGTH = 'SET_TRANSACTION_LENGTH';
export const GET_ARTISTE_MARKET = 'GET_ARTISTE_MARKET';
export const GET_ARTISTE_EARNINGS = 'GET_ARTISTE_EARNINGS';
export const GET_REPORTS = 'GET_REPORTS';
export const START_PURCHASE = 'START_PURCHASE';
export const VALIDATE_PURCHASE = 'VALIDATE_PURCHASE';
export const SET_INSTANCE_LOADING = 'SET_INSTANCE_LOADING';
export const GENERATE_PASSWORD = 'GENERATE_PASSWORD';
export const GET_TOP_CREATORS = 'GET_TOP_CREATORS';
export const GET_HOME_STATS = 'GET_HOME_STATS';
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS';
export const SET_SERIES_STRING = 'SET_SERIES_STRING';
export const SET_COLLECTIONS_NAMES = 'SET_COLLECTIONS_NAMES';
export const CONVERT_CREDITS = 'CONVERT_CREDITS';
export const GET_CREDITS = 'GET_CREDITS';
export const WITHDRAW_HIVE = 'WITHDRAW_HIVE';
export const GET_LICENSE = 'GET_LICENSE';
