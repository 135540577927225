import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setToastNotification } from '../../utils/helpers';

const CuratorCard = ({ curator, isAuth }) => {
  const { name, file, foil, description, series } = curator;
  return (
    <Fragment>
      <div className={`curator__card__wrapper ${foil}`}>
        <img src={file} alt='' width='150' />
        <h1 className='text-medium' style={{fontSize: '20px'}}>{name}</h1>
        <p className='mb-2' style={{fontSize: '13px'}}>
          {description?.length > 125
            ? description.substring(0, 125) + '...'
            : description}
        </p>
        <button
          className='trex__buy__button'
          onClick={() => {
            if (!isAuth) {
              setToastNotification(
                'Please login to purchase this item',
                'info',
              );
              return;
            }
          }}
        >
          <Link to={`checkout/${series}`}>Get {foil}</Link>
        </button>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  usd_price: state.market.usd_price,
  isAuth: state.users.authenticated,
});
export default connect(mapStateToProps, {})(CuratorCard);
