import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getArtisteEarning } from '../../actions/artiste';
import UploadLoader from '../../components/UploadLoader';
import { getEarningsTransactions } from '../../actions/market';
import EarningsTransactionHistory from '../../components/transactions/EarningsTransactionHistory';
import { toFixedWithoutRounding } from '../../utils/helpers';

const Earnings = ({
  getArtisteEarning,
  loading,
  earnings,
  total_earning,
  total_editions,
  getEarningsTransactions,
  history,
  today,
  community_earnings,
  usd_price,
  username,
}) => {
  useEffect(() => {
    getArtisteEarning();
    getEarningsTransactions({ username, types: 'buy,sell' });
  }, [getArtisteEarning, getEarningsTransactions, username, loading]);
  return (
    <Fragment>
      {loading ? (
        <UploadLoader size='small' />
      ) : (
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='earning__card__wrapper top'>
                <h4>
                  Earned Today<i className='fa fa-book-open'></i>
                </h4>
                <p>
                  {toFixedWithoutRounding(today, 2) || 0} <span>TREX</span>
                </p>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='earning__card__wrapper top'>
                <h4>
                  TOTAL EARNED <i className='fa fa-dollar-sign'></i>
                </h4>
                <p>
                  {toFixedWithoutRounding(total_earning, 2) || 0}{' '}
                  <span>TREX</span>
                </p>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='earning__card__wrapper top'>
                <h4>
                  DAYS COUNT <i className='fa fa-fire'></i>
                </h4>
                <p>
                  {toFixedWithoutRounding(total_editions, 2) || 0}{' '}
                  <span>DAYS</span>
                </p>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='earning__card__wrapper top'>
                <h4>
                  PIMP Reward <i className='fa fa-fire'></i>
                </h4>
                <p>
                  {toFixedWithoutRounding(community_earnings, 2) || 0}{' '}
                  <span>TREX</span>
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className='row'>
            <div className='col-md-1'></div>
            <div className='col-md-10'>
              <EarningsTransactionHistory
                transactions={history}
                username={username}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  earnings: state.artiste.earnings,
  history: state.artiste.history,
  today: state.artiste.today,
  loading: state.artiste.loading,
  community_earnings: state.artiste.community_earnings,
  total_earning: state.artiste.total_earning,
  total_editions: state.artiste.total_editions,
  earnings_history: state.market.earnings_transaction_history,
  usd_price: state.market.usd_price,
  username: state.users.username,
});

export default connect(mapStateToProps, {
  getArtisteEarning,
  getEarningsTransactions,
})(Earnings);
