import React, { Fragment, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import UploadLoader from '../UploadLoader';
import { copyToClipboard, toFixedWithoutRounding } from '../../utils/helpers';
import Moment from 'react-moment';

const EarningsTransactionHistory = ({ transactions, username: account }) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [localTrx, setLocalTrx] = useState([]);
  const itemPerPage = 10;
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % transactions.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    setOffset(newOffset);
  };
  useEffect(() => {
    const endOffset = offset + itemPerPage;
    setLocalTrx(transactions?.slice(offset, endOffset));
    setPage(Math.ceil(transactions?.length / itemPerPage));

    setLoading(false);
  }, [transactions, account, offset]);

  return (
    <Fragment>
      <div className='collectible__action__buttons text-center my-3'>
        <h3 className='market__transaction__header my-2'>{'Reward History'}</h3>
        <div className='nft__table__header'>
          <div className='nft__table__header__content'>
            <div className='nft__table__row'>
              <div
                className='nft__table__row__item transaction__history__table'
                // style={{ width: 'auto' }}
              >
                Date
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Description
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Type
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Account
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Type
              </div>
              <div className='nft__table__row__item transaction__history__table'>
                Price
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          <UploadLoader size='small' />
        ) : (
          <>
            {transactions.length ? (
              <div className='nft__table'>
                <div className='nft__table__content'>
                  {localTrx?.map((trx, i) => {
                    return (
                      <div className='nft__table__row' key={i}>
                        <div
                          className='nft__table__row__item transaction__history__table'
                          style={{ width: 'auto' }}
                        >
                          <Moment fromNow>{trx?.timestamp}</Moment>
                        </div>

                        <div
                          datatoggle='tool-tip'
                          dataplacement='top'
                          title='Copy Series'
                          className='nft__table__row__item transaction__history__table pointer'
                          onClick={() => copyToClipboard(trx?.trx_id || 'Coming soon!')}
                        >
                          <span>
                            {`${'distribution-'.toUpperCase()}`}
                          </span>{' '}
                          <Moment
                            className='d-inline-block'
                            format='DD-MM-YYYY'
                          >
                            {JSON.parse(trx?.data)?.date}
                          </Moment>
                        </div>
                        <div className='nft__table__row__item transaction__history__table'>
                          {trx?.type?.toUpperCase() || 'N/A'}
                        </div>
                        <div className='nft__table__row__item transaction__history__table'>
                          {trx?.account}
                        </div>
                        <div className='nft__table__row__item transaction__history__table'>
                          {trx?.license.toUpperCase()}
                        </div>
                        <div className='nft__table__row__item transaction__history__table'>
                          {toFixedWithoutRounding(
                            JSON.parse(trx.data)?.actual_reward,
                            2,
                          ) + ' TREX' || 'N/A'}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className=''>
                <div className='text-center py-5'>
                  <h4>No Rewards History</h4>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className='pagination__action__buttons'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<button>Next</button>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={page}
          activeClassName='active'
          previousLabel={<button>Prev</button>}
          renderOnZeroPageCount={null}
        />
      </div>
    </Fragment>
  );
};

export default EarningsTransactionHistory;
