import React from 'react';
import CountUp from 'react-countup';

const Statistics = ({ stats }) => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='text-center'>
            <h1 className='nfttunz__stats__header'>Statistics</h1>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <div className='nfttunz__countup__wrapper'>
            <CountUp end={stats.totalIssued || 245} redraw={true}>
              {({ countUpRef, start }) => (
                <>
                  <h4>Issued</h4>
                  <span className='d-flex justify-content-center align-items-center'>
                    <span ref={countUpRef || 245} />
                    <span> +</span>
                  </span>
                </>
              )}
            </CountUp>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='nfttunz__countup__wrapper'>
            <CountUp end={stats.totalMinted / 1000 || '64'} redraw={true}>
              {({ countUpRef, start }) => (
                <>
                  <h4>Minted</h4>
                  <span className='d-flex justify-content-center align-items-center'>
                    <span ref={countUpRef} />
                    <span>K</span>
                  </span>
                </>
              )}
            </CountUp>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='nfttunz__countup__wrapper'>
            <CountUp end={stats.users || 308} redraw={true}>
              {({ countUpRef, start }) => (
                <>
                  <h4>Curators</h4>
                  <span className='d-flex justify-content-center align-items-center'>
                    <span ref={countUpRef} />
                    <span>+</span>
                  </span>
                </>
              )}
            </CountUp>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
