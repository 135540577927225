import React from 'react';

const BannerImage = () => {
  return (
    <div className='row'>
      <div
        className='col-md-12 position-relative text-center'
        style={{ height: '480px', width: '100%' }}
      >
        <iframe
          title='Why Trust Exchange?'
          src='https://www.youtube.com/embed/C5xtiaQqVmc?feature=oembed'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen=''
          name='fitvid0'
          style={{
            // position: 'absolute',
            // top: '0',
            // left: '0',
            width: '70%',
            height: '100%',
            border: '0px',
            margin: '0px',
            padding: '0px',
            overflow: 'hidden',
            zIndex: '1',
            // visibility: 'hidden',
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default BannerImage;
