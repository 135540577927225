import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// import VideoPlayer from "react-video-markers";
import {
  getPurchasedCollectibles,
  getCollectibles,
} from '../../actions/collectibles';
import { getNFTInstances } from '../../actions/nfts';
import Cards from '../../components/cards/Cards';
import UploadLoader from '../../components/UploadLoader';

const Collectibles = ({
  username,
  collectibles: { collectibles, purchased_collectibles, loading },
  getPurchasedCollectibles,
  getCollectibles,
  getNFTInstances,
  nfts,
}) => {



  useEffect(() => {
    getPurchasedCollectibles({
      account: username,
    });
    getCollectibles();
    getNFTInstances({ account: localStorage.getItem('username') });
  }, [getPurchasedCollectibles, getCollectibles, getNFTInstances, username]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sub__profile__header'>
              <h6>Here are your acquired licenses</h6>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='file__player'>
              {/* <VideoPlayer
                url="https://cdn.nfttunz.io/QmeLS3vdMX2zxuX6NiaVk8PeUpiK3v2ahZp2JwZ3yETKxw.mpga"
                isPlaying={isPlaying}
                volume={volume}
                onPlay={handlePlay}
                onPause={handlePause}
                onVolume={handleVolume}
              /> */}
            </div>
          </div>
        </div>
        <div className='slick__row' style={{ position: 'relative' }}>
          <div className='arrow__wrapper left__'>
            <i className='fa fa-chevron-left'></i>
          </div>
          <div className='row'>
            {purchased_collectibles === null ? (
              <UploadLoader size='small' />
            ) : (
              <Cards
                songs={purchased_collectibles}
                market={false}
                col={true}
                nfts={nfts}
              />
            )}
          </div>
          <div className='arrow__wrapper right__'>
            <i className='fa fa-chevron-right'></i>
          </div>
        </div>
      </div>
    
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  collectibles: state.collectibles,
  nfts: state.nfts.instances,
  username: state.users.username,
});
export default connect(mapStateToProps, {
  getPurchasedCollectibles,
  getCollectibles,
  getNFTInstances,
})(Collectibles);
