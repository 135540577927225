import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// import VideoPlayer from "react-video-markers";
import {
  getUserCollectibles,
  getCollectibles,
} from '../../actions/collectibles';
import Cards from '../../components/cards/Cards';
import UploadLoader from '../../components/UploadLoader';
import { sleep } from '../../utils/helpers';

const PurchasedCollectibles = ({
  user_collectibles,
  collectible_loading,
  getUserCollectibles,
  username,
  nfts,
}) => {


  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const timer = sleep;
  const handleSearch = async (text) => {
    setSearchText(text);
    setLoading(true);
    await search(text);
    return setLoading(false);
  };
  const search = (text) => {
    const filtered = user_collectibles.filter((purchased_collectible) => {
      return (
        purchased_collectible.creator
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        purchased_collectible.description
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        purchased_collectible.name.toLowerCase().includes(text.toLowerCase())
      );
    });
    console.log(filtered);
    setFilteredData(filtered);
    return timer(1000);
  };


  useEffect(() => {
    getUserCollectibles({
      account: username,
    });
  }, [getUserCollectibles, username]);
  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='sub__profile__header'>
              <h6>Here are your Curated Events</h6>
            </div>
            <div className='search-box d-flex justify-content-center my-3'>
              <form className='d-flex justify-center align-center w-50'>
                <div className='nfttunz__navbar__search__wrapper w-100'>
                  <input
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    className='nfttunz__navbar__search__input text-center w-100'
                    type='search'
                    placeholder='Search by a keyword'
                    aria-label='Search'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'></div>
        </div>
        <div className='slick__row' style={{ position: 'relative' }}>
          {/* EXPERIMENT GOING TO THE LEFT ROUTE WITH THIS BUTTON */}
          {/* <div className='arrow__wrapper left__'>
            <i className='fa fa-chevron-left'></i>
          </div> */}
          <div className='row'>
            {user_collectibles === null && collectible_loading ? (
              <UploadLoader size='small' />
            ) : searchText?.length > 0 &&
              !loading &&
              filteredData?.length < 1 ? (
              <div className='col-md-12'>
                <div className='no__results'>
                  <h6 className='text-center mt-5'>
                    No results found for the keyword "{searchText}"
                  </h6>
                </div>
              </div>
            ) : !user_collectibles?.length && !collectible_loading ? (
              <div className='col-md-12'>
                <div className='no__results'>
                  <h6 className='text-center mt-5'>
                    You have not purchased any licenses yet
                  </h6>
                </div>
              </div>
            ) : (
              <Cards
                songs={
                  filteredData.length > 0
                    ? filteredData
                    : user_collectibles
                }
                market={false}
                col={true}
                nfts={nfts}
              />
            )}
          </div>
          {/* <div className='arrow__wrapper right__'>
            <i className='fa fa-chevron-right'></i>
          </div> */}
        </div>
      </div>

    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  user_collectibles: state.collectibles.user_collectibles,
  collectible_loading: state.collectibles.loading,
  username: state.users.username,
  nfts: state.nfts.instances,
});
export default connect(mapStateToProps, {
  getUserCollectibles,
  getCollectibles,
})(PurchasedCollectibles);
