/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import {
  startPurchase,
  validatePaypalPurchase,
  validateCryptoPurchase,
} from '../../actions/payments';
import { setToastNotification } from '../../utils/helpers';

const CheckoutWithHive = ({
  isShow,
  setIsShow,
  quantity,
  startPurchase,
  validatePaypalPurchase,
  username,
  settings: { tokens },
  payments: { payment_info },
  isShowPaymentInfo,
  setIsShowPaymentInfo,
  series,
  price, foil, type
}) => {
  const [selectedToken, setSelectedToken] = useState('');
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [manualEntry, setManualEntry] = useState(false);
  const handleCryptoPayment = async () => {
    if (quantity % 1000 > 0) {
      return setToastNotification('Insufficient balance', 'error');
    }
    setLoadingPayment(true);
    const payload = {
      username,
      payment_method: 'crypto',
      currency: selectedToken,
      items: [{ type: 'license', price, series, foil, license_type: type }],
    };
    await startPurchase(payload);
    setIsShow(false);
    setIsShowPaymentInfo(true);
    setLoadingPayment(false);
  };
  return (
    <div className='modal-parent'>
      <Modal
        visible={isShow}
        width='500'
        height='400'
        effect='fadeInUp'
        onClickAway={() => setIsShow()}
      >
        <div className=''>
          <div className='modal__header'>
            <h3>Buy License</h3>
          </div>

          <div className='row px-3'>
            <div className='col-md-12'>
              <div className='buy__with__crypto__wrapper'>
                <select
                  className='crypto__select w-100'
                  name='category'
                  id='category'
                  onChange={(e) => {
                    setSelectedToken(e.target.value);
                  }}
                  defaultValue='select'
                >
                  <option value='select' disabled>
                    Select Category
                  </option>
                  {tokens?.map((token, index) => (
                    <option key={index} value={token.symbol}>
                      {token.name} ({token.symbol})
                    </option>
                  ))}
                </select>
                <button
                  className='buy__with__crypto__button'
                  onClick={() => handleCryptoPayment()}
                >
                  {loadingPayment ? (
                    <i className='fas fa-spinner'></i>
                  ) : (
                    'With Crypto'
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className='row px-3'>
            <div className='col-md-12'>
              <h4 className='text-center bold my-2'> </h4>
            </div>
          </div>
          <div className='row px-3'>
            {/* TODO: Try to find out why button doesn't show on first render */}
            {/* <div className='col-md-12'>
              {(() => {
                return (
                  <PaypalCheckout
                    setIsShow={setIsShow}
                    setLoading={setLoading}
                    username={username}
                    startPurchase={startPurchase}
                    validatePaypalPurchase={validatePaypalPurchase}
                  />
                );
              })()}
            </div> */}
          </div>
          <button className='modal__text__button' onClick={() => setIsShow()}>
            close
          </button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.users.username,
  settings: state.settings,
  payments: state.payments,
});

export default connect(mapStateToProps, {
  startPurchase,
  validatePaypalPurchase,
  validateCryptoPurchase,
})(CheckoutWithHive);
