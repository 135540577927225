import { GET_ARTISTE_MARKET, GET_ARTISTE_EARNINGS } from '../actions/types';

const initialState = {
  artiste_market: null,
  loading: true,
  artiste_market_length: 0,
  earnings: null,
  community_earnings: null,
  total_earning: null,
  total_editions: null,
  history: [],
  today: 0,
};

const get_artiste_market = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ARTISTE_MARKET:
      return {
        ...state,
        artiste_market: payload,
        loading: false,
        artiste_market_length: payload.length,
      };
    case GET_ARTISTE_EARNINGS:
      console.log(payload?.reward?.today)
      return {
        ...state,
        earnings: payload?.reward?.today,
        community_earnings: payload?.community_reward?.total,
        today: payload?.reward?.today,
        total_earning: payload?.reward?.total,
        total_editions: payload?.reward?.rewarded_days,
        history: payload?.history?.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
        ),
        loading: false,
      };
    default:
      return state;
  }
};

export default get_artiste_market;
