import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const SellersCard = ({ artiste }) => {
  return (
    <Fragment>
      <Link to={`/@${artiste?.username}`}>
        <div className='sellers__card'>
          <div className='sellers__card__img'>
            <img
              src={
                artiste?.avatar ||
                `https://images.hive.blog/u/${artiste?.username}/avatar/small`
              }
              alt='selle'
            />
          </div>
          <div className='sellers__card__info mx__2'>
            <h3>{artiste?.username}</h3>
            <p>Verified</p>
          </div>
          <div className='sellers__card__info text__right'>
            <h4>Events</h4>
            <p>{artiste?.collectible_count}+</p>
          </div>
          <div className='sellers__card__info text__right'>
            <h4>Validated</h4>
            <p>100+</p>
          </div>
        </div>
      </Link>
    </Fragment>
  );
};

export default SellersCard;
