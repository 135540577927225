import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import imageLoader from '../../assets/images/image_loader.gif';
import { connect } from 'react-redux';
import { toFixedWithoutRounding } from '../../utils/helpers';
import hiveLogo from '../../assets/images/hive_logo.svg';

const Card = ({
  thumbnail,
  file,
  title,
  duration,
  artiste,
  artiste_profile,
  genre,
  series,
  setShowPlayer,
  setFile,
  setThumbnail,
  carouselClass,
  price,
  market,
  auction,
  setPlayerType,
  type,
  editions,
  nsfw,
  usd_price,
  left_editions,
}) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const handleNowPlaying = () => {
    setFile(file);
    setPlayerType(type);
    setThumbnail(thumbnail);
    setShowPlayer(true);
  };

  return (
    <Fragment>
      <div
        className={`music__card__wrapper ${carouselClass ? carouselClass : ''}`}
      >
        <div className='music__card__editions__wrapper d-flex flex-column justify-content-center'>
          <span className='text-center'>
            {left_editions ? left_editions : editions}
          </span>
          <small
            style={{
              fontSize: '8px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            {left_editions ? 'left' : 'editions'}
          </small>
        </div>

        <Link
          to={
            market === true
              ? `/market/${series}`
              : auction === true
              ? `/auction/${series}`
              : `/collectible/${series}`
          }
        >
          <div
            className='music__card__thumbnail'
            style={{ position: 'relative' }}
          >
            {loading ? (
              <img
                className={`card__image ${!loading && 'd-block'}`}
                src={file}
                alt='thumbnail'
                width={100}
                onLoad={() => setLoading(true)}
              />
            ) : (
              <img
                className='blur__image__loader'
                src={imageLoader}
                style={{ opacity: !loading ? 1 : 0 }}
                alt='thumbnail'
                width={100}
                onLoad={() => setLoading(true)}
              />
            )}
          </div>
        </Link>
        <div className='music__card__title'>
          <Link
            to={
              market === true
                ? `/market/${series}`
                : auction === true
                ? `/auction/${series}`
                : `/collectible/${series}`
            }
          >
            <h4 className='text-center'>
              {title?.length > 19 ? `${title.substring(0, 19)}...` : title}
            </h4>
          </Link>
        </div>
        <div className='d-flex justify-content-between py-1'>
          <div className='music__card__artiste'>
            <img
              className='music__card__artiste__avatar'
              src={
                artiste_profile?.avatar ||
                `https://images.hive.blog/u/${artiste}/avatar/small`
              }
              alt={artiste}
            />
            <div className='music__card__artiste__details'>
              <small
                data-toggle='tooltip'
                data-placement='top'
                title='Whitelisted'
              >
                Verified{' '}
                <i
                  style={{
                    color: `${
                      artiste_profile?.artiste === true
                        ? '#63A6FF'
                        : 'lightblue'
                    }`,
                  }}
                  className='fas fa-certificate'
                ></i>
              </small>
              <Link to={`/@${artiste}`}>{artiste}</Link>
            </div>
          </div>
          {/* <div className=''>
            <button
              className='round__button'
              onClick={() => {
                handleNowPlaying();
                setPlayerTitle(title);
                setArtiste(artiste);
                setPlayerSeries(series);
              }}
            >
              {' '}
              <i className='fas fa-play'></i>
            </button>
          </div> */}
        </div>
        <div className='music__card__footer d-flex py-1'>
          <div className='music__card__extras text-center'>
            <h6 data-toggle='tooltip' data-placement='top' title='NFT Type'>
              <i className='fa fa-clock'></i> {duration}
            </h6>
            <h6 data-toggle='tooltip' data-placement='top' title='NFT Genre'>
              <i className='fa fa-book'></i> {genre?.substring(0, 4)}
            </h6>
            <h6 data-toggle='tooltip' data-placement='top' title='Editions'>
              <i className='fa fa-list'></i> {editions}
            </h6>
            <h6 data-toggle='tooltip' data-placement='top' title='NSFW'>
              <i className='fa fa-user-shield'></i> {nsfw ? 'yes' : 'no'}
            </h6>
            {price && (
              <div className='music__card__price__wrapper'>
                <h4>
                  <img src={hiveLogo} alt='hive_logo' width={15} />
                  {price}
                </h4>
                <p>[{toFixedWithoutRounding(usd_price * price)}USD]</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  usd_price: state.market.usd_price,
});
export default connect(mapStateToProps, {})(Card);
