import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const ProfileNav = ({ path, activeTab, setActiveTab }) => {
  return (
    <Fragment>
      <div className='nfttunz__profile__nav'>
        <ul className='nfttunz__profile__links'>
          <li className='nfttunz__profile__link'>
            <Link
              to={`${path}/`}
              onClick={() => setActiveTab('')}
              className={activeTab === '' ? 'active__link' : ''}
            >
              About
            </Link>
          </li>
          <li className='nfttunz__profile__link'>
            <Link
              to={`${path}/collectibles`}
              onClick={() => setActiveTab('collectibles')}
              className={activeTab === 'collectibles' ? 'active__link' : ''}
            >
              Licenses
            </Link>
          </li>
          <li className='nfttunz__profile__link'>
            <Link
              to={`${path}/purchases`}
              onClick={() => setActiveTab('purchases')}
              className={activeTab === 'purchases' ? 'active__link' : ''}
            >
              Curated Events
            </Link>
          </li>
          <li className='nfttunz__profile__link'>
            <Link
              to={`${path}/airdrop`}
              onClick={() => setActiveTab('airdrop')}
              className={activeTab === 'airdrop' ? 'active__link' : ''}
            >
              Manage Airdrops
            </Link>
          </li>
          {/* <li className='nfttunz__profile__link'>
            <Link
              to={`${path}/received`}
              onClick={() => setActiveTab('airdrop')}
              className={activeTab === 'received' ? 'active__link' : ''}
            >
              Rewards
            </Link>
          </li> */}
          <li className='nfttunz__profile__link'>
            <Link
              to={`${path}/earnings`}
              onClick={() => setActiveTab('earnings')}
              className={activeTab === 'earnings' ? 'active__link' : ''}
            >
              Earnings
            </Link>
          </li>
          <li className='nfttunz__profile__link'>
            <Link
              to={`${path}/wallet`}
              onClick={() => setActiveTab('wallet')}
              className={activeTab === 'wallet' ? 'active__link' : ''}
            >
              Wallet
            </Link>
          </li>
          {/* <li className="nfttunz__profile__link">
            <Link to={`${path}/settings`}>Settings</Link>
          </li> */}
        </ul>
      </div>
    </Fragment>
  );
};

export default ProfileNav;
