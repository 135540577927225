import { GET_LICENSE } from '../actions/types';

const initialState = {
  licenses: [],
  license: null,
  loading: true,
};

const licensesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LICENSE:
      return {
        ...state,
        license: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default licensesReducer;
