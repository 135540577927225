import React, { Fragment, useEffect, useRef } from 'react';
import { setToastNotification, toFixedWithoutRounding } from '../utils/helpers';
import axios from '../utils/axios';

const PaypalAccountCreation = ({
  setPaymentMade,
  username,
  startPurchase,
  validatePaypalPurchase,
  price,
}) => {
  const paypalRef = useRef();
  useEffect(() => {
    if (!window.mybutton)
      window.mybutton = window.paypal
        .Buttons({
          style: {
            layout: 'horizontal',
            height: 50,
            width: 250,
            shape: 'rect',
            size: 'responsive',
            tagline: false,
            display: 'paypal',
          },
          createOrder: async (data, actions) => {
            const payload = {
              username: document.getElementById('username').value,
              payment_method: 'paypal',
              currency: 'USD',
              items: [
                {
                  type: 'account_creation',
                  price: toFixedWithoutRounding(price),
                },
              ],
            };

            let purchaseInfo = await axios.post(
              '/purchases/start/credits',
              payload,
            );
            purchaseInfo = purchaseInfo.data;
            return actions.order.create({
              purchase_units: [
                {
                  reference_id: purchaseInfo.uid,
                  invoice_id: purchaseInfo.uid,
                  amount: {
                    value: toFixedWithoutRounding(price, 2),
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
         
            setPaymentMade(true);
            const payload = {
              uid: order.purchase_units[0].reference_id,
              tx: data.orderID,
              type: 'account_creation',
            };
            const purchase = await validatePaypalPurchase(payload);
            
            if (order.status === 'COMPLETED' && purchase.success === 'OK') {
              setToastNotification('Account purchase successful', 'success');
            }
          },
          onError: (err) => {
            setToastNotification(err, 'error');
            console.error(err);
          },
        })
        .render(paypalRef.current);
  }, [setPaymentMade, startPurchase, validatePaypalPurchase, username, price]);
  return (
    <Fragment>
      <div className='mt-3' ref={paypalRef} />
    </Fragment>
  );
};

export default PaypalAccountCreation;
