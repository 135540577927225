import React, { Fragment, useState } from 'react';
import ReactPaginate from 'react-paginate';
import UploadLoader from '../UploadLoader';
import { copyToClipboard } from '../../utils/helpers';
import { useEffect } from 'react';

const Instances = ({
  handleSelectAll,
  instances,
  setInstanceToSell,
  setIsShowAuction,
  sell_cart,
  removeSellCart,
  addSellCart,
  loading,
  setIsShowTransfer,
  series,
}) => {
  const [page, setPage] = useState(null);
  const [offset, setOffset] = useState(0);
  const [localNfts, setLocalNfts] = useState([]);
  const itemPerPage = 10;
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % instances.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    setOffset(newOffset);
  };
  useEffect(() => {
    const endOffset = offset + itemPerPage;
    setLocalNfts(instances.slice(offset, endOffset));
    setPage(Math.ceil(instances.length / itemPerPage));
  }, [instances, series, offset]);

  return (
    <Fragment>
      <div className='collectible__action__buttons text-center my-3'>
        <div className='nft__table__header'>
          <div className='nft__table__header__content'>
            <div className='nft__table__row'>
              <div className='nft__table__row__item'>Edition</div>
              <div className='nft__table__row__item'>Name</div>
              <div className='nft__table__row__item'>Creator</div>
              <div className='nft__table__row__item'>Foil</div>
              <div className='nft__table__row__item nft__instance__actions'></div>
              <div className='nft__table__row__item'>
                <input
                  type='checkbox'
                  name='select_all'
                  // checked={allSelected}
                  onChange={(e) => {
                    // console.log(sellbook);
                    handleSelectAll(e, instances);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          <UploadLoader size='small' />
        ) : (
          <>
            <div className='nft__table'>
              <div className='nft__table__content'>
                {localNfts?.map((instance, i) => {
                  return (
                    <div className='nft__table__row' key={i}>
                      <div className='nft__table__row__item'>
                        {instance?.properties?.edition}
                      </div>
                      <div
                        datatoggle='tool-tip'
                        dataplacement='top'
                        title='Copy Series'
                        className='nft__table__row__item pointer'
                        onClick={() =>
                          copyToClipboard(instance?.properties.series)
                        }
                      >
                        {JSON.parse(instance.properties.metadata)?.name}
                      </div>
                      <div className='nft__table__row__item'>
                        {instance?.account}
                      </div>
                      <div className='nft__table__row__item'>
                        {instance.properties?.foil}
                      </div>
                      <div className='nft__table__row__item nft__instance__actions collectible'>
                        <button
                          onClick={() => {
                            setIsShowTransfer(true);
                            setInstanceToSell(instance);
                          }}
                        >
                          Transfer
                        </button>
                        <button
                          className='margin__left'
                          disabled={true}
                          onClick={() => {
                            setIsShowAuction(true);
                            setInstanceToSell(instance);
                          }}
                        >
                          Sell
                        </button>
                        <button
                          className='margin__left'
                          disabled={true}
                          onClick={() => {
                            setIsShowAuction(true);
                            setInstanceToSell(instance);
                          }}
                        >
                          Burn
                        </button>
                      </div>
                      {/* Implement a select box on Already on Sale, copy the code below */}
                      <div className='nft__table__row__item'>
                        <input
                          type='checkbox'
                          name={instance.nft_id}
                          id=''
                          checked={
                            sell_cart.includes(instance._id.toString())
                              ? true
                              : false
                          }
                          onChange={() =>
                            sell_cart.includes(instance._id.toString())
                              ? removeSellCart(instance._id.toString())
                              : addSellCart([instance._id.toString()])
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <div className='pagination__action__buttons'>
        <ReactPaginate
          breakLabel='...'
          nextLabel={<button>Next</button>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={page}
          activeClassName='active'
          previousLabel={<button>Prev</button>}
          renderOnZeroPageCount={null}
        />
      </div>
    </Fragment>
  );
};

export default Instances;
