import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaDecorator = ({ title, description, imageUrl, imageAlt }) => (
  <Helmet>
    <title>{title}</title>
    <meta property='og:title' content={title} />
    <meta name='description' content={description} />
    <meta property='og:description' content={description} />
    <meta property='og:image' content={imageUrl} />
    <meta
      property='og:url'
      content={
        'https://trustexchange.com/wp-content/uploads/2021/11/home-graphic-stairs.png'
      }
    />
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:image:alt' content={imageAlt} />
    <meta
      name='twitter:site'
      content={`https://www.twitter.com/horlorlahdeh`}
    />
  </Helmet>
);

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

export default MetaDecorator;
