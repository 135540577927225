import {
  GET_COLLECTIBLE,
  GET_COLLECTIBLES,
  GET_USER_COLLECTIBLES,
  GET_ARTISTE_COLLECTIBLES,
  GET_PURCHASED_COLLECTIBLES,
  GET_AIRDROP_COLLECTIBLES,
  GET_RECEIVED_COLLECTIBLES,
  SEARCH_COLLECTIBLES,
  SET_COLLECTIBLES_LENGTH,
  GET_FEEDS,
  GET_REPORTS,
  GET_CURATORS_COLLECTIBLES,
  GET_VALIDATORS_COLLECTIBLES,
  COLLECTIBLES_LOADING,
  SET_COLLECTIONS_NAMES,
} from '../actions/types';

const intialState = {
  collectibles: [],
  collection_names: [],
  curators_collectibles: [],
  validators_collectibles: [],
  user_collectible: null,
  artiste_collectibles: null,
  purchased_collectibles: null,
  purchased_collectibles_remaining: null,
  airdrop_collectibles: null,
  received_collectibles: null,
  collectible: null,
  history_collectibles: [],
  loading: true,
  collectibles_length: null,
  reports: [],
};

export const collectiblesReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COLLECTIONS_NAMES:
      return {
        ...state,
        collection_names: payload,
        loading: false,
      };
    case COLLECTIBLES_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case GET_COLLECTIBLES:
    case SEARCH_COLLECTIBLES:
    case GET_FEEDS:
      return {
        ...state,
        collectibles: payload,
        loading: false,
      };
    case GET_CURATORS_COLLECTIBLES:
      return {
        ...state,
        curators_collectibles: payload,
        loading: false,
      };
    case GET_VALIDATORS_COLLECTIBLES:
      return {
        ...state,
        validators_collectibles: payload,
        loading: false,
      };
    case SET_COLLECTIBLES_LENGTH:
      return {
        ...state,
        collectibles_length: payload,
        loading: false,
      };
    case GET_USER_COLLECTIBLES:
      return {
        ...state,
        user_collectible: payload,
        loading: false,
      };
    case GET_ARTISTE_COLLECTIBLES:
      return {
        ...state,
        artiste_collectibles: payload,
        loading: false,
      };
    case GET_PURCHASED_COLLECTIBLES:
      return {
        ...state,
        purchased_collectibles: payload,
        loading: false,
      };
    case GET_AIRDROP_COLLECTIBLES:
      return {
        ...state,
        airdrop_collectibles: payload,
        loading: false,
      };
    case GET_RECEIVED_COLLECTIBLES:
      return {
        ...state,
        received_collectibles: payload,
        loading: false,
      };
    case GET_COLLECTIBLE:
      return {
        ...state,
        collectible: payload,
        loading: false,
      };
    case GET_REPORTS:
      return {
        ...state,
        reports: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default collectiblesReducer;
