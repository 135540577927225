import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { validateCryptoPurchase } from '../../actions/payments';
import QRCode from 'react-qr-code';
import { copyToClipboard } from '../../utils/helpers';

const CoinPaymentsDetailsModal = ({
  isShow,
  setIsShow,
  username,
  payments: { purchase_data },
}) => {
  return (
    <Fragment>
      <div
        className={`modal ${isShow ? 'display-block' : 'display-none'}`}
        tabIndex='-1'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header bg-dark'>
              <img
                src={
                  'https://cdn.discordapp.com/attachments/856893962894573578/1086039315567620158/image.png'
                }
                alt='modal-header'
                width={40}
              />
              <button
                type='button'
                className='btn-close bg-white'
                onClick={() => {
                  setIsShow(false);
                }}
              ></button>
            </div>
            <div className='modal-body'>
              <p className='my-2 mb-3 text-dark'>
                Use the information below to make payment from{' '}
                <a
                  style={{ color: 'red', fontWeight: 'bold' }}
                  href={`https://wallet.hive.blog/@${username}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  your HIVE Wallet (https://wallet.hive.blog)
                </a>{' '}
                only
              </p>
              <div className='row mb-2'>
                <div className='col-md-8'>
                  <div className='payment__info__card'>
                    <div className='payment__info__card__each__item'>
                      <h6>Address</h6>
                      <p>
                        {purchase_data?.payment_info?.address}{' '}
                        <i
                          className='fas fa-copy text-danger'
                          onClick={() =>
                            copyToClipboard(
                              purchase_data?.payment_info?.address,
                            )
                          }
                        ></i>
                      </p>
                    </div>
                    <div className='payment__info__card__each__item'>
                      <h6>Amount</h6>
                      <p>
                        {purchase_data?.payment_info?.amount}{' '}
                        {purchase_data?.payment_info?.currency}{' '}
                        <i
                          className='fas fa-copy text-danger'
                          onClick={() =>
                            copyToClipboard(purchase_data?.payment_info?.amount)
                          }
                        ></i>
                      </p>
                    </div>
                    {purchase_data?.payment_info?.memo && (
                      <div className='payment__info__card__each__item'>
                        <h6>Memo</h6>
                        <p>
                          {purchase_data?.payment_info?.memo}{' '}
                          <i
                            className='fas fa-copy text-danger'
                            onClick={() =>
                              copyToClipboard(purchase_data?.payment_info?.memo)
                            }
                          ></i>
                        </p>
                      </div>
                    )}
                    <div className='payment__info__card__button__wrapper'>
                      <button
                        className='confirm__buy__with__crypto__button'
                        onClick={() => {
                          setIsShow(false);
                        }}
                      >
                        I have sent coin
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='qr__wrapper'>
                    <QRCode
                      size={130}
                      value={purchase_data?.payment_info?.address || 'hey'}
                    />
                  </div>
                </div>
              </div>
              <p className='payment__info__text text-danger text-small text-bold my-0'>
                NOTE: Feel free to close the dialog while we process your
                payment in the the background. <br />
                <a
                  href={purchase_data?.payment_info?.status_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Click here
                </a>{' '}
                to view transaction status.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  username: state.users.username,
  settings: state.settings,
  payments: state.payments,
});

export default connect(mapStateToProps, {
  validateCryptoPurchase,
})(CoinPaymentsDetailsModal);
